#financeNavigatorEligibilityModal {
  .modal__dialog {
    width: 653px;
  }
  footer {
    border-top: solid 1px #d2d2d2;
    padding: 16px 0 0 0;
    margin-top: 16px;

    button {
      font-weight: 700;
      border-radius: 3px;
      width: 138px;
      height: 35px;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.financeNavigatorEligibilityModalContents {
  &__indicative,
  &__lenderMessages p,
  &__lenderMessages li {
    color: #4a4a4a;
    font-size: 1.4rem;
  }
  &__indicative {
    font-weight: 700;
  }
}
