.dashboardEligibilityMark {
  margin: 0 0 6px 0;
  font-size: 1.3rem;
  color: #30364e;
  font-weight: bold;
  display: flex;

  &--column {
    flex-direction: column;
  }

  img,
  span {
    display: inline;
    vertical-align: bottom;
  }
}

.dashboardEligibilityMark--high,
.dashboardEligibilityMark--veryhigh {
  .dashboardEligibilityMark__dot {
    background-color: #6bc348;
  }

  .dashboardEligibilityMark__status {
    color: #6bc348;
  }
}

.dashboardEligibilityMark--preapproved {
  .dashboardEligibilityMark__dot {
    background-color: #367f14;
  }

  .dashboardEligibilityMark__status {
    color: #367f14;
  }
}

.dashboardEligibilityMark--medium {
  .dashboardEligibilityMark__dot {
    background-color: #f4ba52;
  }

  .dashboardEligibilityMark__status {
    color: #f4ba52;
  }
}

.dashboardEligibilityMark--low {
  .dashboardEligibilityMark__dot {
    background-color: #e66c6c;
  }

  .dashboardEligibilityMark__status {
    color: #e66c6c;
  }
}

.dashboardEligibilityMark--unknown,
.dashboardEligibilityMark--unabletoassist,
.dashboardEligibilityMark--notoffered {
  .dashboardEligibilityMark__dot {
    background-color: #5a595b;
  }

  .dashboardEligibilityMark__status {
    color: #5a595b;
  }
}

.dashboardEligibilityMark__dot {
  width: 13px;
  height: 13px;
  float: left;
  border-radius: 50%;
  margin: 2px 4px 0 0;
}

.dashboardEligibilityMark__status {
  font-weight: bold;
}

.dashboardEligibilityMark__text {
  color: #494949;
  font-weight: 400;
}

.dashboardEligibilityMark__source {
  font-weight: 600;
  margin-left: 8px;
}
