.quoteCardStatus {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
  background: #d3d3d3;

  &:first-child {
    font-size: 18px;
    font-weight: bold;
  }

  &__viewDetails {
    font-size: 14px;

    margin: 0;
    margin-top: 5px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    color: #060a0d;
    text-decoration: underline;
  }

  &--preApproved,
  &--veryHigh {
    color: #306c14;
    border-color: #64bc46;
    background-color: #c3e4b5;
  }

  &--high {
    color: #4c982a;
    border-color: #64bc46;
    background-color: #f2fff2;
  }

  &--medium {
    color: #fff;
    border-color: #ec6639;
    background-color: #f4ba52;
  }

  &--low {
    color: #e66c6c;
    border-color: #e66c6c;
    background-color: #ffc8cc;
  }
  &--notOffered,
  &--unableToAssist {
    color: #fff;
    border-color: #980008;
    background-color: #980008;
    .quoteCardStatus__viewDetails {
      color: #fff;
    }
  }

  &--unknown {
    color: #30364e;
    border-color: #30364e;
    background-color: #e1e1e1;
  }

  &--expired {
    color: #6b6b6b;
    border-color: #6b6b6b !important;
    background-color: #e1e1e1;
  }
}

// Specific Quote Card Header Figures Specificity override
.quoteCard__cell.quoteCardStatus {
  &--unableToAssist {
    border-color: #980008;
  }
  &--unknown {
    border-color: #30364e;
  }
  &--preApproved,
  &--veryHigh {
    border-color: #64bc46;
  }
  &--high {
    border-color: #64bc46;
  }
  &--medium {
    border-color: #ec6639;
  }
  &--low {
    border-color: #e66c6c;
  }
}
