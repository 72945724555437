.cfcProspectsWidget {
  margin: 0 0 20px 0;
  -webkit-overflow-scrolling: touch;
}

.cfcProspectsWidget__logo {
  margin: 7px 0 -7px 0;
  width: 210px;
}

.cfcProspectsWidget__filterOuter {
  width: 450px;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.cfcProspectsWidget__applicantContainer {
  background-color: #fff;
  font-size: 1.4rem;
  text-align: center;
}

.cfcProspectsWidget__noApplications {
  background-color: #fff;
  font-size: 1.4rem;
  padding: 20px;
  text-align: center;
}

.cfcProspectsWidget__paginationContainer {
  padding-bottom: 5px;
}
