@import '../../styles/themes/themes.scss';

.sidebarItem {
  height: 70px;
  width: 100%;
  z-index: 2000;
  position: relative;
  color: #fff;
  padding: 5px 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease;

  &:hover {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;

    &:hover {
      width: 100%;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebarItem__link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 0;
  margin: 0;
}

.sidebarItem__icon {
  width: 70px;
  height: 70px;
  background-size: 38px;
  background-repeat: no-repeat;
  background-position: center;
}

.sidebarItem__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarItem__text {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
}

.sidebarItem__icon--home {
  background-image: url(../../styles/icons/sidebar/home.svg);
}

.sidebarItem__icon--homeDashboard {
  background-image: url(../../styles/icons/sidebar/home-dashboard.svg);
}

.sidebarItem__icon--addCustomer {
  background-image: url(../../styles/icons/sidebar/add-customer.svg);
}

.sidebarItem__icon--viewCustomers {
  background-image: url(../../styles/icons/sidebar/customers.svg);
}

.sidebarItem__icon--reporting {
  background-image: url(../../styles/icons/sidebar/reporting.svg);
}

.sidebarItem__icon--stock {
  background-image: url(../../styles/icons/sidebar/stock.svg);
}

.sidebarItem__icon--settings {
  background-image: url(../../styles/icons/sidebar/settings.svg);
}

.sidebarItem__icon--help {
  background-image: url(../../styles/icons/sidebar/question.svg);
}

.sidebarItem__icon--signOut {
  background-image: url(../../styles/icons/sidebar/sign-out.svg);
}

.sidebarItem__icon--digitalDeals {
  background-image: url(../../styles/icons/sidebar/deals.svg);
}

.sidebarItem__icon--insights {
  background-image: url(../../styles/icons/sidebar/insights.svg);
}

.sidebarItem__icon--sendDeal {
  background-image: url(../../styles/icons/sidebar/send-deal.svg);
}

.sidebarItem__icon--payments {
  background-image: url(../../styles/icons/sidebar/payments.svg);
}

.sidebarItem__icon--privacyPolicy {
  background-image: url(../../styles/icons/sidebar/privacy-policy.svg);
}

.sidebarItem__icon--impressum {
  background-image: url(../../styles/icons/sidebar/impressum.svg);
  background-size: 34px;
}
