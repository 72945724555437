@font-face {
	font-family: "dealer-platform-icons";
	src: url("../fonts/icons/dealer-platform-icons.woff2?a793d7db4c03a125cad677eb3c691b1e") format("woff2"),
url("../fonts/icons/dealer-platform-icons.woff?a793d7db4c03a125cad677eb3c691b1e") format("woff"),
url("../fonts/icons/dealer-platform-icons.svg?a793d7db4c03a125cad677eb3c691b1e#dealer-platform-icons") format("svg");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: dealer-platform-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon-account-settings:before {
	content: "\f101";
}
.icon-add-customer:before {
	content: "\f102";
}
.icon-affordability:before {
	content: "\f103";
}
.icon-alert-new:before {
	content: "\f104";
}
.icon-alert:before {
	content: "\f105";
}
.icon-application-alt:before {
	content: "\f106";
}
.icon-application:before {
	content: "\f107";
}
.icon-approved:before {
	content: "\f108";
}
.icon-arrow-circle:before {
	content: "\f109";
}
.icon-assign:before {
	content: "\f10a";
}
.icon-bank:before {
	content: "\f10b";
}
.icon-bar-chart:before {
	content: "\f10c";
}
.icon-bike:before {
	content: "\f10d";
}
.icon-bin:before {
	content: "\f10e";
}
.icon-broadcast:before {
	content: "\f10f";
}
.icon-burger:before {
	content: "\f110";
}
.icon-calendar:before {
	content: "\f111";
}
.icon-camera:before {
	content: "\f112";
}
.icon-car:before {
	content: "\f113";
}
.icon-check:before {
	content: "\f114";
}
.icon-clicks:before {
	content: "\f115";
}
.icon-clock:before {
	content: "\f116";
}
.icon-connection:before {
	content: "\f117";
}
.icon-consumer:before {
	content: "\f118";
}
.icon-credit-score:before {
	content: "\f119";
}
.icon-cross-2:before {
	content: "\f11a";
}
.icon-cross:before {
	content: "\f11b";
}
.icon-customers:before {
	content: "\f11c";
}
.icon-dealership-user:before {
	content: "\f11d";
}
.icon-dealership:before {
	content: "\f11e";
}
.icon-deals_icon:before {
	content: "\f11f";
}
.icon-discover:before {
	content: "\f120";
}
.icon-distance:before {
	content: "\f121";
}
.icon-down:before {
	content: "\f122";
}
.icon-e-sign:before {
	content: "\f123";
}
.icon-edit-schemes:before {
	content: "\f124";
}
.icon-edit:before {
	content: "\f125";
}
.icon-email:before {
	content: "\f126";
}
.icon-employment:before {
	content: "\f127";
}
.icon-error:before {
	content: "\f128";
}
.icon-export:before {
	content: "\f129";
}
.icon-eye:before {
	content: "\f12a";
}
.icon-finance-check:before {
	content: "\f12b";
}
.icon-finance-leads:before {
	content: "\f12c";
}
.icon-full-page-ad:before {
	content: "\f12d";
}
.icon-global-settings:before {
	content: "\f12e";
}
.icon-group-report:before {
	content: "\f12f";
}
.icon-handshake:before {
	content: "\f130";
}
.icon-home-dashboard:before {
	content: "\f131";
}
.icon-home:before {
	content: "\f132";
}
.icon-info:before {
	content: "\f133";
}
.icon-information:before {
	content: "\f134";
}
.icon-inprogress:before {
	content: "\f135";
}
.icon-insights-car:before {
	content: "\f136";
}
.icon-insights-report:before {
	content: "\f137";
}
.icon-insights-star:before {
	content: "\f138";
}
.icon-league-table:before {
	content: "\f139";
}
.icon-left:before {
	content: "\f13a";
}
.icon-line-chart:before {
	content: "\f13b";
}
.icon-linked-consumer:before {
	content: "\f13c";
}
.icon-location:before {
	content: "\f13d";
}
.icon-mail_success:before {
	content: "\f13e";
}
.icon-map:before {
	content: "\f13f";
}
.icon-medal:before {
	content: "\f140";
}
.icon-mileage:before {
	content: "\f141";
}
.icon-minus:before {
	content: "\f142";
}
.icon-motorhome:before {
	content: "\f143";
}
.icon-multi-actions:before {
	content: "\f144";
}
.icon-note:before {
	content: "\f145";
}
.icon-notification:before {
	content: "\f146";
}
.icon-online-order:before {
	content: "\f147";
}
.icon-opened-deal:before {
	content: "\f148";
}
.icon-padlock:before {
	content: "\f149";
}
.icon-partial-application:before {
	content: "\f14a";
}
.icon-payment_icon:before {
	content: "\f14b";
}
.icon-pending-circle:before {
	content: "\f14c";
}
.icon-pending:before {
	content: "\f14d";
}
.icon-pie-chart:before {
	content: "\f14e";
}
.icon-play:before {
	content: "\f14f";
}
.icon-plus:before {
	content: "\f150";
}
.icon-price-position:before {
	content: "\f151";
}
.icon-printer:before {
	content: "\f152";
}
.icon-proceed:before {
	content: "\f153";
}
.icon-product-settings:before {
	content: "\f154";
}
.icon-question:before {
	content: "\f155";
}
.icon-quick-quote-eur:before {
	content: "\f156";
}
.icon-quick-quote:before {
	content: "\f157";
}
.icon-quote:before {
	content: "\f158";
}
.icon-rejected:before {
	content: "\f159";
}
.icon-report:before {
	content: "\f15a";
}
.icon-reporting:before {
	content: "\f15b";
}
.icon-repropose:before {
	content: "\f15c";
}
.icon-right:before {
	content: "\f15d";
}
.icon-search:before {
	content: "\f15e";
}
.icon-self-service-deals:before {
	content: "\f15f";
}
.icon-send-deal:before {
	content: "\f160";
}
.icon-settings:before {
	content: "\f161";
}
.icon-sign-out:before {
	content: "\f162";
}
.icon-sm-ageIcon:before {
	content: "\f163";
}
.icon-sm-bhpIcon:before {
	content: "\f164";
}
.icon-sm-bodyStyleIcon:before {
	content: "\f165";
}
.icon-sm-budgetIcon:before {
	content: "\f166";
}
.icon-sm-ccIcon:before {
	content: "\f167";
}
.icon-sm-classIcon:before {
	content: "\f168";
}
.icon-sm-colorIcon:before {
	content: "\f169";
}
.icon-sm-doorIcon:before {
	content: "\f16a";
}
.icon-sm-ecoIcon:before {
	content: "\f16b";
}
.icon-sm-fuelIcon:before {
	content: "\f16c";
}
.icon-sm-locationIcon:before {
	content: "\f16d";
}
.icon-sm-locationIcon2:before {
	content: "\f16e";
}
.icon-sm-makeIcon:before {
	content: "\f16f";
}
.icon-sm-mileageIcon:before {
	content: "\f170";
}
.icon-sm-modelIcon:before {
	content: "\f171";
}
.icon-sm-powerIcon:before {
	content: "\f172";
}
.icon-sm-powerIcon2:before {
	content: "\f173";
}
.icon-sm-recencyIcon:before {
	content: "\f174";
}
.icon-sm-taxIcon:before {
	content: "\f175";
}
.icon-sm-transmissionIcon:before {
	content: "\f176";
}
.icon-sm-trimIcon:before {
	content: "\f177";
}
.icon-spinner:before {
	content: "\f178";
}
.icon-star_1:before {
	content: "\f179";
}
.icon-star_gray_1:before {
	content: "\f17a";
}
.icon-star_gray:before {
	content: "\f17b";
}
.icon-star:before {
	content: "\f17c";
}
.icon-stock-settings:before {
	content: "\f17d";
}
.icon-stock:before {
	content: "\f17e";
}
.icon-summary:before {
	content: "\f17f";
}
.icon-table-chart:before {
	content: "\f180";
}
.icon-tag:before {
	content: "\f181";
}
.icon-tick-in-circle:before {
	content: "\f182";
}
.icon-tick-speech:before {
	content: "\f183";
}
.icon-tick:before {
	content: "\f184";
}
.icon-unique-buyers:before {
	content: "\f185";
}
.icon-update:before {
	content: "\f186";
}
.icon-upload:before {
	content: "\f187";
}
.icon-validation-cross:before {
	content: "\f188";
}
.icon-validation-tick:before {
	content: "\f189";
}
.icon-van:before {
	content: "\f18a";
}
.icon-vaps:before {
	content: "\f18b";
}
.icon-warning:before {
	content: "\f18c";
}
.icon-webshop:before {
	content: "\f18d";
}
