.container {
  display: grid;
  grid-template-columns: 275px auto;
  width: 100%;
  height: 100vh;
  font-weight: 600;
}

.content {
  padding-bottom: 25px;
  height: 100%;
  font-weight: 400;
  font-size: 12px;
  box-sizing: border-box;
}

.title {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 20px;
}

.cancelButton {
  width: 100px;
  float: left;
  padding-bottom: 30px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.date {
  font-family: 'Open Sans', Arial, sans-serif;
}

.content table,
.content td:not(:empty),
.content th:not(:empty) {
  border: 1px solid;
  border-collapse: collapse;
  margin-bottom: 2rem;
  padding: 1rem;
}
.content table thead th:empty {
  display: none;
}
