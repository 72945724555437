.lender__onboarding {
  margin: 0 2rem;
}

.lender__onboarding__description {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 2rem;
  margin: 0 2rem 2rem 2rem;
}

.lender__onboarding__ShowHide {
  display: flex;
  justify-content: end;
  padding: 3rem 2rem 0 0;
}

.lender__onboarding__bankDetails {
  display: flex;
  flex-direction: row;
}

.lender__onboarding__BankWarning {
  justify-self: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 1rem;
  margin: 2rem;
}
