@import '../../../styles/themes/themes.scss';

.button {
  width: 100%;
  min-width: 100px;
  display: inline-block;
  position: relative;
  color: white;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  perspective: 500px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 600;
  margin: 0;
  border-radius: 5px;
  vertical-align: top;
  text-align: center;

  &.isDisabled {
    background: #a0a0a0;

    &:hover {
      background: #a0a0a0;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }

  &.hasError {
    background: #e74c3c !important;
    border-color: #e74c3c !important;
    color: white !important;

    &:hover {
      background: #e74c3c;
    }
  }

  &.button--isLoading .button__spinner {
    animation: fadeIn 0.5s;
  }
}
.button--anchor {
  margin-bottom: 10px;
}

.button__text,
.button__text > * {
  opacity: 0;
}

.button__state {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  outline: 1px solid transparent;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
}

.button__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
}

/* Sizes */

.button--normal {
  line-height: 36px;
  height: 40px;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  .button__icon {
    font-size: 29px;
  }

  .button__spinner {
    width: 20px;
    height: 20px;
  }
}

.button--small {
  line-height: 26px;
  font-size: 12px;
  padding: 0 10px;

  .button__icon {
    font-size: 21px;
  }

  .button__spinner {
    width: 20px;
    height: 20px;
  }
}

.button--large {
  line-height: 58px;
  font-size: 18px;

  .button__icon {
    font-size: 29px;
  }

  .button__spinner {
    width: 35px;
    height: 35px;
  }
}

/* Colours */

.button--primary {
  border: 1px solid;
  color: #ffffff;
  @include theme(
    (
      background: bodyMain,
      border-color: bodyMain
    )
  );

  &:hover {
    @include themeDarken(
      (
        background: bodyMain,
        border-color: bodyMain
      )
    );
  }

  .button__spinner {
    border-color: #fff;
  }
}

.button--primaryDisabled {
  border: 1px solid #989898;
  color: #ffffff;
  background: #989898;

  .button__spinner {
    border-color: #fff;
  }
}

.button--secondary {
  background: none;
  border: 1px solid;

  @include theme(
    (
      color: bodySecondary,
      border-color: bodySecondary
    )
  );

  &:hover {
    color: #ffffff !important;
    @include themeDarken(
      (
        background-color: bodySecondary,
        border-color: bodySecondary
      )
    );
  }

  .button__spinner {
    @include theme(
      (
        border-color: bodyMain
      )
    );
  }
}

.button--select {
  background: none;
  border: 1px solid;
  color: white;

  @include theme(
    (
      background-color: bodyMain,
      border-color: bodyMain
    )
  );
}

.button--selectDisabled {
  background: none;
  border: 1px solid #989898;
  color: #989898;

  .button__spinner {
    border-color: #989898;
  }
}

.button {
  &:disabled {
    pointer-events: none;
  }
}

.button--secondaryDisabled {
  background: none;
  border: 1px solid #989898;
  color: #989898;

  .button__spinner {
    border-color: #989898;
  }
}

.button--cancel {
  background: none;
  border: 1px solid #f55e5e;
  color: #f55e5e;
  text-align: center;

  &:hover {
    background-color: darken(#f55e5e, 15%);
    border-color: darken(#f55e5e, 15%);
    color: #ffffff;
  }

  .button__spinner {
    border-color: #f55e5e;
  }
}

.button--custom {
  background: inherit;
  color: inherit;
  border: inherit;
  outline: inherit;
  font-weight: inherit;
  line-height: inherit;
}

/* Animations */

.button-animation-enter {
  opacity: 0;
  transform: rotateX(-60deg) translateY(48px);
  transition: 0.4s ease-in-out;
}

.button-animation-enter-active {
  transform: rotateX(0deg) translateY(0);
  opacity: 1;
}

.button-animation-exit {
  opacity: 1;
  transform: rotateX(0deg) translateY(0);
  transition: 0.4s ease-in-out;
}

.button-animation-exit-active {
  opacity: 0;
  transform: rotateX(60deg) translateY(-48px);
}
